'use strict';

// "global-require": 0,


require('normalize.css');
require('reset.css');
require('tachyons');

const rot = require('rot');

const injectTwitterScript = () => {
    function addJS(jsCode) {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.innerText = jsCode;
        document.getElementsByTagName('head')[0].appendChild(s);
    }
    addJS(`
    window.twttr = (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);
      t._e = [];
      t.ready = function(f) {
        t._e.push(f);
      };
      return t;
    })(document, "script", "twitter-wjs");
  `);
};

exports.onClientEntry = () => {};

exports.onInitialClientRender = () => {
    injectTwitterScript();
};

exports.onRouteUpdate = () => {
    // decrypt any encrypted emails
    const allAElements = document.getElementsByTagName('a');
    Array.from(allAElements).forEach((el) => {
        const { href } = el;
        if (href.indexOf('mailto:') === 0) {
            const rot13Email = href.replace('mailto:', '').replace('__ENCRYPTED__', '');
            const email = rot(rot13Email, -13).replace('_AT_', '@');
            el.setAttribute('href', `mailto://${email}`);
        }
    });

    // load twitter widgets
    if (typeof twttr !== 'undefined' && window.twttr.widgets && typeof window.twttr.widgets.load === 'function') {
        window.twttr.widgets.load();
    }
};
