module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-41622618-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"sampleRate":100,"siteSpeedSampleRate":50},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-accessibilityjs/gatsby-browser.js'),
      options: {"plugins":[],"injectStyles":"\n                .accessibility-error {\n                    box-shadow: 0 0 3px 1px #f00;\n                    background-color: rgba(255, 0, 0, 0.25);\n                    position: relative;\n                }\n                .accessibility-error:before {\n                    content: \"A11Y\";\n                    position: absolute;\n                    top: 0;\n                    left: 0;\n                    color: #fff;\n                    font-size: 10px;\n                    background-color: rgba(255, 0, 0, 0.5);\n                    transform: translateY(-100%);\n                }\n            "},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
